/** @jsxImportSource @emotion/react */
import { useContext, useEffect,useRef } from "react"
import { InfoBarWrapperStyle, SwipeToCloseStyle } from "../BarStyle.css"
import 'react-loading-skeleton/dist/skeleton.css'
import { ExplorerContext } from "../ExplorerContext"
import { useMatch, useNavigate, useOutlet } from "react-router-dom";
import { css } from "@emotion/react";
import { useIsVisible } from "../Hooks/useIsVisible";




export const InfoBar = () => {

    const { openInfoBar, setCurrentHex, closeInfoBar} = useContext(ExplorerContext)
    const closeTriggerRefLeft = useRef(null)
    const closeTriggerRefRight = useRef(null)
    const navigate = useNavigate()
    const outlet = useOutlet();
    const egwRoute  = useMatch("/egw/:id")
    const hexRoute = useMatch("/hex/:hex")
    const hexRoute2 = useMatch("/:hex")
    const swipeRoute = useMatch("/:hex/:id")
    //ugly hack
    let activeRoute = outlet != null && (egwRoute || hexRoute || swipeRoute || hexRoute2)

    useIsVisible(closeTriggerRefLeft,(intersecting) => {
        if(intersecting){
            closeInfoBar(false)
            setCurrentHex({hex: null, egw: null})
            navigate("/")
        }
    })
    useIsVisible(closeTriggerRefRight,(intersecting) => {
        if(intersecting){
            closeInfoBar(false)
            setCurrentHex({hex: null, egw: null})
            navigate("/")
        }
    })

    useEffect(() => {
        if (egwRoute || hexRoute || hexRoute2 || swipeRoute)
            openInfoBar(true)
    },[]) // eslint-disable-line react-hooks/exhaustive-deps


    return <div css={[InfoBarWrapperStyle(activeRoute),css`z-index:2;`]}>
            <div css={SwipeToCloseStyle}>
                <div ref={closeTriggerRefLeft}>CloseTrigger</div>
            </div>
            {outlet}
            <div css={SwipeToCloseStyle}>
                <div ref={closeTriggerRefRight}>CloseTrigger</div>
            </div>
    </div>

}