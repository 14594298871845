/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { medium, large} from '../Global.css'


export const HeaderContainerStyle = (open) => css`
    
    z-index: 3;
    background-color: rgba(29, 29, 29,0.9);
    border-bottom-width: 0px;
    border-bottom-style: solid;
    position: relative;
    ${open ? `
        border-image: none;
        ${medium}{
            border-bottom-width: 1px;
            border-image: linear-gradient(90deg, transparent 0%, transparent 50%, rgba(0,0,0, 0.3) 50%) 1;
        }
        ${large}{
            border-image: linear-gradient(90deg, transparent 0%, transparent 476px, rgba(0,0,0, 0.3) 476px) 1;
        }` : 
        "border-bottom-color: rgba(254, 215, 0, 0.5);"}
    padding: 9px 9px 9px 15px;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    ${medium}{
        padding: 30px 18px;
    }

    .logo{
        h1{
            ${medium}{
                svg{
                    transform: scale(150%);
                    transform-origin: left;
                }
            }
        }
    }

`

export const MenuStyle = (open) => css`

    display: none;
    gap: 8px;


    ${open ? css`
        display: flex;
        position: absolute;
        right: 0;
        top: 100%;
        width: 100%;
        height: 100svh;
        background-color: rgba(29, 29, 29,0.9);
        flex-direction: column;
        align-items: center;
        padding-top: 7%;
        grid-gap: 7%;
        
        li{
            width: 100%;
            a{
                font-size: 40px;
                width: 100%;
                display: inline-block;
                text-align: center;
                padding: 20px 0px;
                text-decoration: none;
                font-family: "Inter";
                ${medium}{
                    font-size: 20px;
                }
            }
        }
    ` : null}
    
    a{
        text-decoration: none;
        font-family: "Inter";
        pointer-events: auto;
        ${medium}{
            font-size: 20px;
        }
        &:hover{
            color:#FFE500;
        }
    
    }

    
    ${medium}{
        display: flex;
        gap: 20px;
        position: unset;
    }

`

export const ButtonMenuStyle = (open) => css`

    background: none;
    border: none;
    svg{
        fill:#fff;
        width: 30px;
        height: 30px;
    }


    ${medium}{
        display: none;
    }


`