
const SourcefulBolt = () => {

    return <svg width="131" height="27" viewBox="0 0 131 27" fill="none">
        <path d="M42.9425 11.4403C46.7159 11.8748 49.3458 12.8353 49.3458 16.4943C49.3458 19.1471 47.0589 21.4797 42.7596 21.4797C38.2773 21.4797 36.1733 18.5983 36.1733 15.6253H39.192C39.2378 16.8602 39.8095 18.9184 42.7596 18.9184C45.4352 18.9184 46.2357 17.5692 46.2357 16.6773C46.2357 15.5796 45.5267 14.7791 42.7596 14.4132C38.1858 13.8187 36.7222 12.3322 36.7222 9.58791C36.7222 7.07233 38.9862 5.01413 42.8053 5.01413C46.9903 5.01413 48.7055 7.52971 48.7512 10.1368H45.8011C45.8011 9.08479 45.0922 7.57544 42.7596 7.57544C40.4041 7.57544 39.8095 8.76463 39.8095 9.4507C39.8095 10.0682 40.1068 11.1201 42.9425 11.4403Z" fill="white"/>
        <path d="M55.7954 9.03905C59.5688 9.03905 61.8328 11.8291 61.8328 15.2594C61.8328 18.6897 59.5688 21.4797 55.7954 21.4797C52.022 21.4797 49.758 18.6897 49.758 15.2594C49.758 11.8291 52.022 9.03905 55.7954 9.03905ZM55.7954 18.8269C57.785 18.8269 58.9056 17.2261 58.9056 15.2594C58.9056 13.2927 57.785 11.6918 55.7954 11.6918C53.8058 11.6918 52.6852 13.2927 52.6852 15.2594C52.6852 17.2261 53.8058 18.8269 55.7954 18.8269Z" fill="white"/>
        <path d="M66.9593 21.4797C64.3523 21.4797 62.6143 19.7874 62.6143 16.3571V9.22201H65.5415V15.3509C65.5415 18.0723 66.2504 18.8269 67.7826 18.8269C69.4749 18.8269 71.03 16.9288 71.03 14.1388V9.22201H73.9572V21.2968H71.03V20.5421C71.03 19.8103 71.1901 19.3758 71.3959 18.8955C71.4874 18.6897 71.6246 18.3696 71.3273 18.301C71.1444 18.2552 71.0757 18.3924 70.9843 18.5754C70.3897 19.7646 69.0633 21.4797 66.9593 21.4797Z" fill="white"/>
        <path d="M78.3477 11.8062C78.4849 11.8291 78.5993 11.6461 78.6221 11.5775C79.3539 9.90807 80.0171 9.03905 82.9672 9.03905V12.012C79.5598 11.7833 78.5764 12.9954 78.5764 16.0598V21.2968H75.6492V9.22201H78.5764V9.8852C78.5764 10.4112 78.4392 10.8686 78.2334 11.2802C78.1419 11.486 78.0733 11.7605 78.3477 11.8062Z" fill="white"/>
        <path d="M82.6508 15.2594C82.6508 11.669 85.0063 9.03905 88.7568 9.03905C92.1414 9.03905 94.1539 11.3259 94.3597 13.7958H91.4782C91.2952 12.6523 90.4034 11.6918 88.7568 11.6918C86.7443 11.6918 85.578 13.1555 85.578 15.2594C85.578 17.3633 86.7443 18.8269 88.7568 18.8269C90.4034 18.8269 91.2952 17.8665 91.4782 16.723H94.3597C94.1539 19.1928 92.1643 21.4797 88.7568 21.4797C85.0749 21.4797 82.6508 18.8498 82.6508 15.2594Z" fill="white"/>
        <path d="M106.56 15.0993C106.56 15.4195 106.537 15.6482 106.491 15.9912H97.7094C97.9153 17.8207 99.1044 18.9184 100.934 18.9184C102.329 18.9184 103.038 18.1637 103.381 17.0889H106.354C105.828 19.5816 104.136 21.4797 100.911 21.4797C97.0005 21.4797 94.8508 18.8498 94.8508 15.2594C94.8508 11.486 97.1835 9.03905 100.865 9.03905C103.655 9.03905 106.56 10.7085 106.56 15.0993ZM97.8238 13.9787H103.632C103.518 12.5151 102.649 11.4174 100.957 11.4174C98.9672 11.4174 98.1211 12.538 97.8238 13.9787Z" fill="white"/>
        <path d="M112.253 7.39249C111.018 7.39249 110.995 7.48397 110.995 8.81037V9.22201H114.288V11.7833H110.995V21.2968H108.068V11.7833H106.238V9.22201H108.068V8.53594C108.068 5.63159 109.211 4.83118 111.635 4.83118H114.288V7.39249H112.253Z" fill="white"/>
        <path d="M119.049 21.4797C116.442 21.4797 114.704 19.7874 114.704 16.3571V9.22201H117.631V15.3509C117.631 18.0723 118.34 18.8269 119.872 18.8269C121.564 18.8269 123.119 16.9288 123.119 14.1388V9.22201H126.047V21.2968H123.119V20.5421C123.119 19.8103 123.279 19.3758 123.485 18.8955C123.577 18.6897 123.714 18.3696 123.417 18.301C123.234 18.2552 123.165 18.3924 123.074 18.5754C122.479 19.7646 121.153 21.4797 119.049 21.4797Z" fill="white"/>
        <path d="M127.738 4.83118H130.666V21.2968H127.738V4.83118Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0946 25.8755C20.0306 25.8755 25.6533 20.2528 25.6533 13.3168C25.6533 6.3808 20.0306 0.758057 13.0946 0.758057C6.15857 0.758057 0.535828 6.3808 0.535828 13.3168C0.535828 20.2528 6.15857 25.8755 13.0946 25.8755ZM13.8973 5.89186L8.03473 13.0076C7.66258 13.4593 8.0556 14.1297 8.63153 14.0256L11.3564 13.5332C11.8182 13.4497 12.2053 13.8835 12.0699 14.3329L10.3374 20.0841C10.1436 20.7274 10.9586 21.1851 11.4071 20.6848L17.988 13.3426C18.3654 12.9215 18.0381 12.2551 17.4741 12.2964L14.5089 12.5133C14.0907 12.5439 13.7604 12.1642 13.8487 11.7542L14.9967 6.4238C15.1354 5.77994 14.3161 5.38354 13.8973 5.89186Z" fill="#FFE500"/>
    </svg>

}

export default SourcefulBolt