import { useState } from "react";
import { useEffect } from "react";

export function useIsVisible(ref, callback) {
    const [isIntersecting, setIntersecting] = useState(false);
    useEffect(() => {
        if (!ref.current) return;
        const observer = new IntersectionObserver(([entry]) =>{
            setIntersecting(entry.isIntersecting)
            callback && callback(entry.isIntersecting)
        }, {threshold: 0.5});
  
        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, [ref, callback]);
  
    return isIntersecting;
  }