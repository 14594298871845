/** @jsxImportSource @emotion/react */
import Skeleton from "react-loading-skeleton"
import { ProductionBoxStyles, ContentFooterLinks,ScopesButtonGroupStyle, SunIconStyle } from "../InfobarContent.css"
import { InfoSectionStyle, OnlineStyle, OfflineStyle, UpdatedStyle } from "../../BarStyle.css"
import ProductionGraph from "../../ProductionGraph/ProductionGraph"
import { Tooltip } from "react-tooltip"
// import Settings from "../Settings"
import InfoIcon from "../../Icons/InfoIcon"
import { useQuery } from "@tanstack/react-query"
import { useContext, useState } from "react"
import { formatISO  } from "date-fns"
import { ExplorerContext } from "../../ExplorerContext"
import Expandable from "../Expandable"
import SunIcon from "../../Icons/SunIcon"
import { truncateString } from "../InfobarContent"
import CopyToClipboard from "../CopyToClipboard"

const minutesDiff = (dateTimeValue2, dateTimeValue1) => {
    var differenceValue =(dateTimeValue2.getTime() - dateTimeValue1.getTime()) / 1000;
    differenceValue /= 60;
    return Math.abs(Math.round(differenceValue));
}

const skeletonMargin = (bottom) => { return {"marginBottom":bottom}}
const numberOfMsInADay = 86400000
const scopes = [
    {
        startDateFn:() => {
            const date = formatISO(new Date(new Date() - (30 * numberOfMsInADay)), { representation: 'date' })
            return date
        },
        key: "30d",
        displayName: "30 days",
        chartType: "Bar",
        tooltipFormat: "yyyy-MM-dd",
        label: "kWh"
    },
    {
        startDateFn:() => {
            return formatISO(new Date(new Date() - (7*numberOfMsInADay)))
        },
        key: "7d",
        displayName: "7 days",
        resolution: "1h"
    },
    {
        startDateFn:() => {
            return formatISO(new Date(new Date() - (1*numberOfMsInADay)))
        },
        displayName: "24 hours",
        key: "24h",
        resolution: "10m"
    }
]
const SolarProduction = ({ gatewayRequest,der, background, isValidated }) => {

    const [activeScope, setActiveScope] = useState("24h")
    // const [showValidationData, setShowValidationData] = useState(localStorage?.getItem("showValidationData") ? JSON.parse(localStorage?.getItem("showValidationData")) : false)
    const [showValidationData] = useState(false)
    const { loadHistogramProductionData, loadDailykWhProductionData } = useContext(ExplorerContext)
    const {data, isRefetching, isSuccess} = useQuery({
        queryKey: ["proddata", gatewayRequest.data?.id ,activeScope, showValidationData],
        enabled: gatewayRequest.isSuccess,
        queryFn: async () => {
            const tz = gatewayRequest.data.timeZone
            const includeNullData = isHardware() ? true : false;
            if (selectedScope.key === "30d") {
                return await loadDailykWhProductionData(der.sn, selectedScope.startDateFn(), tz)
            }
            else {
                let validationId = null;
                if(isValidated && showValidationData){
                    validationId = gatewayRequest.data.links[0];
                }
                return await loadHistogramProductionData(der.sn, selectedScope.startDateFn(), selectedScope.resolution, tz, includeNullData, validationId)
            }
        },
        refetchInterval: 10000
    })
    const isOnline = () => {
        if (!data) return false;
        if (!data.derData.solar?.latest) return false;
        if(!gatewayRequest.data) return false;
        const minutes = isHardware() ? 10 : 60;
        return minutesDiff(new Date(data.derData.solar.latest.ts), new Date()) < minutes;
    }
    const isHardware = () => {
        return gatewayRequest.data?.typeOf === "Hardware" ?? true;
    }
    const selectedScope = scopes.find(x => x.key === activeScope);


    return <div css={[InfoSectionStyle, background]}>
        <h3><SunIcon style={SunIconStyle} />{der.name} {!data ? <></> : isOnline() ? <span css={OnlineStyle}>Online</span> : <span css={OfflineStyle}>Offline</span>}</h3>
        <span css={UpdatedStyle}>Updated: {data?.derData.solar?.latest ? new Date(data.derData.solar.latest.ts).toLocaleString("sv") : "-"}</span>
        {/* {isValidated && <Settings showValidationData={showValidationData} showValidationDataCallback={(show) => {
            localStorage.setItem("showValidationData", show)
            setShowValidationData(show)
        }} />} */}
        <div css={ProductionBoxStyles}>
            <div>
                <Tooltip id="currentPower" place={"top-start"} noArrow content="The latest saved power reading." />
                <h4 data-tooltip-id="currentPower"><span className="hideInMobile">Current </span>Power <InfoIcon /></h4>
                <p className={isRefetching ? "refetching" : null}>{isOnline() ?
                    `${((data.derData.solar?.latest?.power ?? 0) / 1000).toFixed(2)} kW` :
                    data ? "-" : <Skeleton style={skeletonMargin("0px")} inline={true} width={"75px"} />
                }
                </p>
            </div>
            <div>
                <Tooltip id="today" place={"top-start"} noArrow html={`The amount of kWh this gateway has produced<br/>since 00:00 in the ${gatewayRequest?.data?.timeZone} timezone.`} />
                <h4 data-tooltip-id="today">Today <InfoIcon /></h4>
                <p>{data?.derData.solar ? `${data.derData.solar.today} kWh` : <Skeleton style={skeletonMargin("0px")} inline={true} width={"75px"} />}</p>
            </div>
        </div>

        {isSuccess ?
            <>
                <div css={ScopesButtonGroupStyle}>
                    {scopes.map(s => <button key={s.key} className={activeScope === s.key ? "active" : null} onClick={() => setActiveScope(s.key)}>{s.displayName}</button>)}
                </div>
                <ProductionGraph 
                    data={data?.derData.solar.dailykWh ? data.derData.solar.dailykWh : data.derData.solar.histogram}
                    validationData={data?.validationData?.histogram}
                    type={selectedScope.chartType}
                    tooltipFormat={selectedScope.tooltipFormat}
                    label={selectedScope.label}
                    startTime={selectedScope.startDateFn()}
                    colorMode={isHardware(gatewayRequest.data) ? "hardware" : "software"}
                />
            </>
            :
            <Skeleton height={"167px"} style={skeletonMargin("25px")} /> 
        }
        <Expandable>
            <h3>DER metadata</h3>
            <ul css={[ContentFooterLinks]}>
                <li>
                    Model: <span>{gatewayRequest.data?.inverterData?.model}</span>
                </li>
                <li>
                    Make: <span>{gatewayRequest.data?.inverterData?.selectedMake}</span>
                </li>
                <li>
                    Rated Power: <span>{gatewayRequest.data?.inverterData?.ratedPower??"-"} kW</span>
                </li>
                <li>
                    Sn: <span title={der.lastSeen} className="derid">{truncateString(der.sn)}</span><CopyToClipboard text={der.sn} copiedClass={"deridCopied"} />
                </li>
                
            </ul>
        </Expandable>
    </div>
}


export default SolarProduction