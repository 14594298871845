/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons'

const wrapperStyle = css`
    position: relative;
    padding: 0 5%;
    &.open{
        margin-top: 20px;
    }
    h3{
        font-size: 16px;
        font-weight: 300;
    }
`

const toggleSwitch = css`

    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    border: 1px solid transparent;
    background: linear-gradient(rgba(25, 33, 61, 1) 0%,  rgba(25, 33, 61, 0.6) 100%);
    box-shadow: -0px -1px 5px rgb(0, 0, 0, 0.9);
    position: absolute;
    left: calc(50% - 15px);
    padding: 0;
    
    svg{
        fill: white;
        position: relative;
        top: -2px;
        left: -2px;
        &.up{
            top: -3px;
        }
    }

    &:hover, &:focus{
        box-shadow: 0 0 5px rgba(255, 229, 0, 0.8);
        border: 1px solid rgba(255, 229, 0, 0.6);
        outline: none;
    }
    &:active{
        outline: none;
    }
    
    

`


const Expandable = ({ children }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div css={wrapperStyle} className={isExpanded ? "open" : null}>
            {isExpanded && <div>{children}</div>}
            <button css={toggleSwitch} onClick={toggleExpand} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                {isExpanded ?  <UilAngleUp className="up" /> : <UilAngleDown className="down" />}
            </button>
        </div>
    );
};

export default Expandable;