import React from 'react';

// const CopyClipToClipboard = ({ text }) => {
//     const copyToClipboard = () => {
//         navigator.clipboard.writeText(text).then(() => {
//             alert('Copied to clipboard!');
//         }).catch(err => {
//             console.error('Failed to copy: ', err);
//         });
//     };

//     return (
//         <button onClick={copyToClipboard}>
//             Copy to Clipboard
//         </button>
//     );
// };

// export default CopyClipToClipboard;


const CopyToClipboardIcon = () => {
    return <svg class="svg-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path d="M682.666667 42.666667H170.666667C123.52 42.666667 85.333333 80.853333 85.333333 128v597.333333h85.333334V128h512V42.666667z m128 170.666666H341.333333c-47.146667 0-85.333333 38.186667-85.333333 85.333334v597.333333c0 47.146667 38.186667 85.333333 85.333333 85.333333h469.333334c47.146667 0 85.333333-38.186667 85.333333-85.333333V298.666667c0-47.146667-38.186667-85.333333-85.333333-85.333334z m0 682.666667H341.333333V298.666667h469.333334v597.333333z"  />
        </svg>
}

export default CopyToClipboardIcon;
